import {
    Scene,
    Mesh,
    SubMesh,
    StandardMaterial,
    MultiMaterial,
    Color3,
    Texture
} from "@babylonjs/core";
import { BimApi } from "@sweco-ps/embedded";
import { GeoLocation } from "./GeoLocation";

export class BaseMap {
    private _subdivisions = {
        h: 16,
        w: 16
    };
    private _latitude = 0;
    private _longitude = 0;
    private _mapZoom = 0;
    private _tiledGround!: Mesh;
    constructor(private _coordinate: GeoLocation, private _api: BimApi) {
        this.addMapTiles(
            _coordinate.latitude,
            _coordinate.longitude,
            17,
            this._api.viewer.scene
        );
    }
    //longitude - The longitude coordinate
    //latitude - The latitude coordinate
    //mapZoom is the 1-20 value for what scale of map that should be returned by open streetmap
    //scene - The babylonjs scene from TwinfinityAPI (const scene = (this.api.viewer.scene as unknown) as Scene;)

    public visible(show: boolean) {
        const mapMesh = this._api.viewer.scene.getMeshByName("Tiled Ground");
        if (mapMesh) {
            if (mapMesh.isEnabled() != show) {
                mapMesh.setEnabled(show);
            }
        }
    }

    public swapWms(name: "ortho" | "black") {
        const latitudeTile = this.latitudeToTile(this._latitude, this._mapZoom);
        const longitudeTile = this.longitudeToTile(
            this._longitude,
            this._mapZoom
        );
        if (name === "ortho") {
            this._tiledGround.position.x = -78;
            this._tiledGround.position.z = 40;
            this._tiledGround.position.y = this._coordinate.offset;
            this._tiledGround.rotation.y = 1.52;
            this._tiledGround.scaling.copyFromFloats(3.85, 3.85, 3.85);
            for (let row = 0; row < this._subdivisions.h; row++) {
                for (let col = 0; col < this._subdivisions.w; col++) {
                    const material = this._api.viewer.scene.getMaterialByName(
                        "material" + row + "-" + col
                    ) as StandardMaterial;
                    if (!material) {
                        continue;
                    }

                    material.diffuseTexture = new Texture(
                        // 'http://stamen-tiles-a.a.ssl.fastly.net/toner/' +
                        // 'https://api.mapbox.com/v4/mapbox.satellite/' +
                        "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/" +
                            this._mapZoom +
                            "/" +
                            (longitudeTile + col - this._subdivisions.w / 2) +
                            "/" +
                            (latitudeTile - row + this._subdivisions.h / 2) +
                            "?access_token=pk.eyJ1IjoiYmJyYW5kIiwiYSI6ImNrZjNtcjJybzA0cDcyc25vbGF3Mm51dG4ifQ.GZnZaDr2h53jKk0Aofm-uQ",

                        this._api.viewer.scene
                    );
                }
            }
        } else if (name === "black") {
            this._tiledGround.position.x = -84;
            this._tiledGround.position.z = 35;
            this._tiledGround.position.y = this._coordinate.offset;
            this._tiledGround.rotation.y = 1.52;
            this._tiledGround.scaling.copyFromFloats(3.85, 3.85, 3.85);
            for (let row = 0; row < this._subdivisions.h; row++) {
                for (let col = 0; col < this._subdivisions.w; col++) {
                    const material = this._api.viewer.scene.getMaterialByName(
                        "material" + row + "-" + col
                    ) as StandardMaterial;
                    if (!material) {
                        continue;
                    }

                    material.diffuseTexture = new Texture(
                        "http://stamen-tiles-a.a.ssl.fastly.net/toner/" +
                            this._mapZoom +
                            "/" +
                            (longitudeTile + col - this._subdivisions.w / 2) +
                            "/" +
                            (latitudeTile - row + this._subdivisions.h / 2) +
                            ".png",
                        this._api.viewer.scene
                    );
                }
            }
        }
    }

    private addMapTiles(
        latitude: number,
        longitude: number,
        mapZoom: number,
        scene: Scene
    ) {
        this._latitude = latitude;
        this._longitude = longitude;
        this._mapZoom = mapZoom;
        const xmin = -325;
        const zmin = -325;
        const xmax = 325;
        const zmax = 325;

        // This is used by babylonjs, not sure how this works. Needs to be sorted in final version
        const precision = {
            w: 2,
            h: 2
        };
        // Create the Tiled Ground
        const latitudeTile = this.latitudeToTile(latitude, mapZoom);
        const longitudeTile = this.longitudeToTile(longitude, mapZoom);
        this._tiledGround = Mesh.CreateTiledGround(
            "Tiled Ground",
            xmin,
            zmin,
            xmax,
            zmax,
            this._subdivisions,
            precision,
            scene
        );
        // Part 2 : Create the multi material
        // Create differents materials
        const whiteMaterial = new StandardMaterial("White", scene);
        whiteMaterial.diffuseColor = new Color3(1, 1, 1);
        const blackMaterial = new StandardMaterial("Black", scene);
        blackMaterial.diffuseColor = new Color3(0, 0, 0);
        // Create Multi Material
        const multimat = new MultiMaterial("multi", scene);
        for (let row = 0; row < this._subdivisions.h; row++) {
            for (let col = 0; col < this._subdivisions.w; col++) {
                const material = new StandardMaterial(
                    "material" + row + "-" + col,
                    scene
                );
                material.diffuseTexture = new Texture(
                    // 'http://stamen-tiles-a.a.ssl.fastly.net/toner/' +
                    // 'https://api.mapbox.com/v4/mapbox.satellite/' +
                    "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/" +
                        mapZoom +
                        "/" +
                        (longitudeTile + col - this._subdivisions.w / 2) +
                        "/" +
                        (latitudeTile - row + this._subdivisions.h / 2) +
                        "?access_token=pk.eyJ1IjoiYmJyYW5kIiwiYSI6ImNrZjNtcjJybzA0cDcyc25vbGF3Mm51dG4ifQ.GZnZaDr2h53jKk0Aofm-uQ",
                    scene
                );
                material.diffuseTexture.wrapU = Texture.CLAMP_ADDRESSMODE;
                material.diffuseTexture.wrapV = Texture.CLAMP_ADDRESSMODE;
                material.backFaceCulling = false;
                multimat.subMaterials.push(material);
                material.specularColor.copyFromFloats(0, 0, 0);
            }
        }
        // Part 3 : Apply the multi material
        // Define multimat as material of the tiled ground
        this._tiledGround.material = multimat;
        // Needed variables to set subMeshes
        const verticesCount = this._tiledGround.getTotalVertices();
        const tileIndicesLength =
            this._tiledGround.getIndices()!.length /
            (this._subdivisions.w * this._subdivisions.h);
        // Set subMeshes of the tiled ground
        this._tiledGround.subMeshes = [];
        let index = 0;
        let base = 0;
        for (let row = 0; row < this._subdivisions.h; row++) {
            for (let col = 0; col < this._subdivisions.w; col++) {
                const submesh = new SubMesh(
                    index++,
                    0,
                    verticesCount,
                    base,
                    tileIndicesLength,
                    this._tiledGround
                );
                this._tiledGround.subMeshes.push(submesh);
                base += tileIndicesLength;
            }
        }
        this._tiledGround.position.x = -78;
        this._tiledGround.position.z = 40;
        this._tiledGround.position.y = this._coordinate.offset;
        this._tiledGround.rotation.y = 1.52;
        this._tiledGround.scaling.copyFromFloats(3.85, 3.85, 3.85);
        (window as any).tile = this._tiledGround;
        console.log(
            this._tiledGround.position,
            this._tiledGround.rotation,
            this._tiledGround.scaling
        );

        return this._tiledGround;
    }

    // Calculates the correct longitude tile number from Openstreetmaps tile server from a longitude value
    private longitudeToTile(longitude: number, zoom: number) {
        return Math.floor(((longitude + 180) / 360) * Math.pow(2, zoom));
    }

    // Calculates the correct latitude tile number from Openstreetmaps tile server from a latitude value
    private latitudeToTile(latitude: number, zoom: number) {
        return Math.floor(
            ((1 -
                Math.log(
                    Math.tan((latitude * Math.PI) / 180) +
                        1 / Math.cos((latitude * Math.PI) / 180)
                ) /
                    Math.PI) /
                2) *
                Math.pow(2, zoom)
        );
    }
}
