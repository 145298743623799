const iconRoomMap = new Map([
    ["RESEPTION", "bell-concierge.svg"],
    ["CYKELRUM", "bicycle.svg"],

    ["OMKLÄDNINGSRUM", "booth-curtain.svg"],
    ["OMKLÄDN.", "booth-curtain.svg"],
    ["OMKL.", "booth-curtain.svg"],

    ["LAGER", "box-open-full.svg"],
    ["STÄD", "broom.svg"],
    ["DEFAULT", "buildings.svg"],

    ["KONTOR", "chair-office.svg"],

    ["KAPPRUM", "clothes-hanger.svg"],

    ["VILRUM", "couch.svg"],
    ["LOUNGE", "couch.svg"],

    ["HISS", "elevator.svg"],

    ["RESTAURANG", "fork-knife.svg"],
    ["MATSAL", "fork-knife.svg"],
    ["LUNCHRUM", "fork-knife.svg"],

    ["PENTRY", "microwave.svg"],
    ["MIKROKÖK", "microwave.svg"],
    ["KÖK", "microwave.svg"],
    ["PENTRY/PAUS", "microwave.svg"],

    ["KAFFE", "mug-saucer.svg"],
    ["KAFFESTN", "mug-saucer.svg"],
    ["KAFFESTATION", "mug-saucer.svg"],
    ["CAFÉ", "mug-saucer.svg"],

    ["GARAGE", "garage-car.svg"],

    ["SAMTALSRUM", "phone.svg"],
    ["SAMTAL", "phone.svg"],
    ["TELERUM", "phone.svg"],
    ["TELE", "phone.svg"],

    ["MÖTE", "presentation-screen.svg"],
    ["MÖTE 2P", "presentation-screen.svg"],
    ["MÖTE 4P", "presentation-screen.svg"],
    ["MÖTE 6P", "presentation-screen.svg"],
    ["MÖTE 10P", "presentation-screen.svg"],
    ["MÖTE 12P", "presentation-screen.svg"],
    ["MÖTEN", "presentation-screen.svg"],
    ["MÖTESRUM", "presentation-screen.svg"],

    ["SKRIVARRUM", "print.svg"],
    ["SKRIVARE", "print.svg"],
    ["SKRIV", "print.svg"],

    ["RETURMOTT", "recycle.svg"],

    ["KONFERENSRUM", "screen-users.svg"],
    ["KONF", "screen-users.svg"],
    ["KONFERENS", "screen-users.svg"],

    ["BUTIK", "shop.svg"],
    ["BUTIK", "shop.svg"],

    ["DUSCH", "shower.svg"],

    ["WC", "sink.svg"],
    ["HWC", "sink.svg"],

    ["EL", "square-bolt.svg"],
    ["TRAPPHALL", "stairs.svg"],
    ["TRAPPH", "stairs.svg"],
    ["TRAPPHUS", "stairs.svg"],
    ["TRAPPA", "stairs.svg"],
    ["SOPRUM", "trash-can.svg"],
    ["SOPRUM-HUSHÅLL", "trash-can.svg"],
    ["FILMSAL", "tv.svg"]
]);

export function RoomIcon(name: string, config: { width: number, height: number, classList: string[] } = { width: 32, height: 32, classList: ['roomTypeIcon'] }): HTMLImageElement {
    const url = `./icons/${iconRoomMap.has(name) ? iconRoomMap.get(name) : iconRoomMap.get('DEFAULT')}`;

    const img = document.createElement("img");
    img.width = config.width;
    img.height = config.height;
    img.src = url;
    if (config.classList.length == 0) {
        img.classList.add('roomTypeIcon');
    } else {
        config.classList.forEach(c => {
            img.classList.add(c);
        });
    }

    return img;
}
