import { BimApi, BimIfcBuildingStorey, BimIfcSpace } from "@sweco-ps/embedded";

export interface StatisticsFloor {
    floorName: string;
    floor: BimIfcBuildingStorey;
    loas: StatisticsLoa[];
}
export interface StatisticsLoa {
    loa: BimIfcSpace;
    roomTypes: StatisticsRoomType[];
    loaArea: number;
}
export interface StatisticsRoomType {
    type: string;
    rooms: BimIfcSpace[];
    typeArea: number;
}

export class TenantStatistics {
    public tenantFloors: StatisticsFloor[];

    constructor(public selectedTenantId: string, private _api: BimApi) {
        this.tenantFloors = this._createTenantBimStatistics();
    }

    private _getTenantFloors() {
        const floors: Set<BimIfcBuildingStorey> = new Set();
        this._api.ifc.spaces.forEach((space: BimIfcSpace) => {
            const bip = space.properties.data.BIP;
            if (
                space.spaceType === "LOA" &&
                bip.hyresobjektsnummer === this.selectedTenantId &&
                space.enclosingFloor
            ) {
                floors.add(space.enclosingFloor);
            }
        });
        return floors;
    }

    private _getTenantLoaByFloor(floor: BimIfcBuildingStorey) {
        const loas: Set<BimIfcSpace> = new Set();
        this._api.ifc.spaces.forEach((space: BimIfcSpace) => {
            const bip = space.properties.data.BIP;
            if (
                space.spaceType === "LOA" &&
                bip.hyresobjektsnummer === this.selectedTenantId &&
                space.enclosingFloor === floor
            ) {
                loas.add(space);
            }
        });
        return loas;
    }
    private _getRoomsByFloor(floor: BimIfcBuildingStorey) {
        const rooms: Set<BimIfcSpace> = new Set();
        this._api.ifc.spaces.forEach((space: BimIfcSpace) => {
            if (space.spaceType === "ROOM" && space.enclosingFloor === floor) {
                rooms.add(space);
            }
        });
        return rooms;
    }

    private _createTenantBimStatistics() {
        const floors = this._getTenantFloors();
        const result: StatisticsFloor[] = [];
        floors.forEach((floor) => {
            // Get LOA and Rooms by each floor
            const loas = this._getTenantLoaByFloor(floor);
            const rooms = this._getRoomsByFloor(floor);
            const loaAndRooms: StatisticsLoa[] = [];
            loas.forEach((loa) => {
                // Filter rooms by LOA geometry
                const roomsInCurrentLoa = loa.geometricallyIntersects(
                    [...rooms],
                    true,
                    true
                ) as BimIfcSpace[];
                const roomTypes: Map<string, BimIfcSpace[]> = new Map();
                roomsInCurrentLoa.forEach((room) => {
                    const bip = room.properties.data.BIP;

                    if (bip?.spacename) {
                        if (!roomTypes.has(bip.spacename.toString())) {
                            roomTypes.set(bip.spacename.toString(), [room]);
                            return;
                        }
                        const spaceArray = roomTypes.get(
                            bip.spacename.toString()
                        ) as BimIfcSpace[];
                        spaceArray.push(room);
                    }
                });

                const roomTypesEntries = [...roomTypes.entries()]
                    .map((v) => {
                        let area = 0;
                        v[1].forEach((p) => {
                            area += p.calculatedArea;
                        });
                        return { type: v[0], rooms: v[1], typeArea: area };
                    })
                    .sort((a, b) => {
                        return b.typeArea - a.typeArea;
                    });

                loaAndRooms.push({
                    loaArea: loa.calculatedArea,
                    loa,
                    roomTypes: roomTypesEntries
                });
            });
            result.push({ floorName: floor.name, floor, loas: loaAndRooms });
        });
        return result;
    }
}
