import {
  BimChange,
  BimChangeIfc,
  BimChangeLayer,
  BimChangeType,
  BimContainer,
  BimContainerInfo,
  BimIfcClass,
  BimUserInfo,
  Discipline,
  HttpResponseType,
  LayerApiClient,
  Lcid,
  MailMessageId,
  MessageApiClient,
  Permission,
  TwinfinityApiClient,
  TwinfinityInfo,
  TypedResponse,
  UploadApiClient,
  createMockTypedResponse,
} from "@sweco-ps/embedded";

/**
 * Loads container and IFC data from local files. Mostly used for dev purposes.
 * @hidden
 */
export class CloudflareApiClient implements TwinfinityApiClient {
  private readonly _rootDummyProjectId = "root";
  private readonly _anchorElement = document.createElement("a");
  public readonly layers: LayerApiClient;
  public readonly messages: MessageApiClient;
  public readonly upload: UploadApiClient;

  private readonly _mockUser = {
    id: "1",
    name: "Pelle Fake",
    firstName: "Pelle",
    lastName: "Fake",
    privacyPolicyUrl: "http://foo.com/privacyPolicy",
    email: "pellefake@faker.com",
  };

  private readonly _buildings: Record<string, string[]> = {
    hq: [
        "A/A.ifc",
        "V/V-57_P04.ifc",
        "V/V-57_P05.ifc",
        "V/V-57_P06.ifc",
        "V/V-57_P07.ifc",
        "V/V-57_P08.ifc"
    ]
  }

  constructor() {
    // TODO: Create own dev class when we actually need to have implemented
    // methods
    this.layers = {
      update: (): Promise<TypedResponse<BimChangeLayer>> => {
        throw new Error("Not implemented");
      },
      add(): Promise<TypedResponse<BimChangeLayer>> {
        throw new Error("Not implemented");
      },
    };

    this.messages = {
      sendMailMessage: (): Promise<TypedResponse<MailMessageId>> => {
        throw new Error("Not implemented");
      },
    };

    this.upload = {
      createFileSession: () => {
        throw new Error("Not implemented");
      },
      canAppendFileVersion: () => {
        throw new Error("Not implemented");
      },
      canCreateFileInFolder: () => {
        throw new Error("Not implemented");
      },
    };


  }

  public canDelete(): boolean {
    return false;
  }

  public deleteChanges(): Promise<TypedResponse<number>[]> {
    throw new Error("not implemented");
  }

  public getUserInfo(): Promise<TypedResponse<Omit<BimUserInfo, "id">>> {
    return Promise.resolve(createMockTypedResponse({ value: this._mockUser }));
  }

  /** See {@link TwinfinityApiClient.getContainerInfo} */
  public getContainerInfo(
    containerOrUrl?: BimContainer | URL
  ): Promise<TypedResponse<BimContainerInfo>> {
    let containerId: string | null = this._rootDummyProjectId;
    if (containerOrUrl) {
      if ("url" in containerOrUrl) {
        containerOrUrl = new URL(containerOrUrl.url);
      }
      containerId = this.getContainerId(containerOrUrl);
      if (!containerId) {
        throw new Error(`${containerOrUrl.href} does not exist.`);
      }
    }

    return Promise.resolve(
      createMockTypedResponse({
        value: {
          id: containerId,
          title: containerId,
          language: "en-US",
          info: {
            title: containerId,
            language: "en-US",
          },
          user: this._mockUser,
        },
      })
    );
  }

  /** See {@link TwinfinityApiClient.getContainers} */
  public async getContainers(id?: string): Promise<BimContainer[]> {
    let ret: BimContainer[] = Object.keys(this._buildings).map((id) => {
      const serverRelativeUrl = "?container=" + id;
      const url = this.makeAbsoluteUrl(serverRelativeUrl).toString();
      return {
        type: BimChangeType.Container,
        ownerSystem: 'cloudflare',
        containerId: "-",
        url: this.makeAbsoluteUrl(serverRelativeUrl).toString(),
        id: id,
        format: "web",
        name: id,
        version: 0,
        metadata: {},
        language: "en-US",
        lcid: Lcid.Swedish,
        modifiedUtc: new Date(),
        hasGeometry: true,
        serverRelativeUrl: serverRelativeUrl,
        apiUrl: url.toString(),
        containerType: "ps",
        etag: "123",
        permissions: Permission.Read,

      };
    });
    if (id !== undefined) {
      ret = ret.filter((c) => c.id === id);
    }
    return Promise.resolve(ret);
  }

  // TODO Add some kind of filter here so users can decide what type of files to get
  // perhaps filtered on metadata or similar. Also allows us to get specific files etc etc
  // graphql might be a good fit for these kind of things. If we do that we might not need
  // functions for different types of changes (annotations etc).
  // Initially we can use some kind of jsonb to perform the search?
  /** See {@link TwinfinityApiClient.getIfcChanges} */
  public async getIfcChanges(
    containerOrUrl: BimContainer | URL
  ): Promise<BimChangeIfc[]> {
    if ("url" in containerOrUrl) {
      if (containerOrUrl.containerId === this._rootDummyProjectId) {
        return [];
      }
      containerOrUrl = new URL(containerOrUrl.url);
    }

   
    const containerId = this.getContainerId(containerOrUrl);
    if (!containerId) {
      throw new Error("Missing ?container=<name> on query string");
    }
    const fileNames = this._buildings[containerId];

    const ret: BimChangeIfc[] = [];


    for (const fn of fileNames) {
      const apiUrl = this.makeAbsoluteUrl(new URL(`https://blobs.hq-demo.twinfinity.com/${containerId}/${fn}`));
      const fakeUrl = apiUrl;
      const metadataResponse = await this.get<any>(
        fakeUrl.origin +
          fakeUrl.pathname +
          ".metadata" +
          fakeUrl.search +
          fakeUrl.hash,
        HttpResponseType.json
      );
      const metadata =
        metadataResponse.status === 200 ? await metadataResponse.value : {};
      const ifcChange: BimChangeIfc = {
        type: BimChangeType.Ifc,
        ownerSystem: 'cloudflare',
        url: fakeUrl.toString(),
        containerId: containerId,
        blobId: "blob_" + fn,
        id: fn,
        format: "ifc",
        discipline: Discipline.getOrAddFromServerRelativeUrl(fakeUrl.pathname),
        productCount: metadata.statistics?.productCount ?? -1,
        name: fn,
        version: 0,
        etag: "123",
        permissions: Permission.Read,
        metadata: metadata,
        floors: metadata.floors ?? [],
        classes: (metadata.classes ?? []).map((c: string) =>
          BimIfcClass.getOrAdd(c)
        ),

        apiUrl: apiUrl.toString(),
        resourceUrl: {
          idx: new URL(
            fakeUrl.origin +
              fakeUrl.pathname +
              ".idx" +
              fakeUrl.search +
              fakeUrl.hash
          ),
          geom: new URL(
            fakeUrl.origin +
              fakeUrl.pathname +
              ".geom" +
              fakeUrl.search +
              fakeUrl.hash
          ),
          prop: new URL(
            fakeUrl.origin +
              fakeUrl.pathname +
              ".prop" +
              fakeUrl.search +
              fakeUrl.hash
          ),
        }
      };
      ret.push(ifcChange);
    }

    return await Promise.resolve(ret);
  }

  public getChanges<T extends BimChange = BimChange>(): Promise<TypedResponse<T[]>> {
    throw new Error("Not implemented");
  }

  /** See {@link TwinfinityApiClient.get} */
  public async get<T>(
    absoluteOrRelativeUrl: URL | string,
    converter: (r: Response) => TypedResponse<T>
  ): Promise<TypedResponse<T>> {
    const response = await fetch(absoluteOrRelativeUrl.toString());
    return converter(response);
  }

  /** See {@link TwinfinityApiClient.getInfo} */
  public async getInfo(): Promise<TwinfinityInfo> {
    return {
      name: "Dev",
      logoImageUrl: "http://foo.com/twinfinity/bar.png",
      backgroundImageUrl: "http://foo.com/twinfinity/bar.png",
      psUrl: "http://foo.com",
      version: "0.0.0.0",
    };
  }

  private makeAbsoluteUrl(url: URL | string): URL {
    this._anchorElement.href = url.toString();
    return new URL(this._anchorElement.href);
  }

  private getContainerId(url: URL): string | null {
    return url.searchParams.get("container") ?? "root";
  }
}
