export class FloorToolTip {
    private _tip: HTMLDivElement;
    private _label: HTMLDivElement;
    constructor() {
        this._tip = document.getElementById("floorTip") as HTMLDivElement;
        this._label = document.getElementById("floorTipText") as HTMLDivElement;
    }

    public set visible(visibility: boolean) {
        if (visibility == true) {
            this._tip.style.display = "grid";
        } else {
            this._tip.style.display = "none";
        }
    }

    public moveTo(button: HTMLButtonElement) {
        let offset = 1;
        if (button.classList.contains("floorBtnClicked")) {
            offset = 4;
        }
        const buttonPosition = button.getBoundingClientRect();
        this._tip.style.left = (buttonPosition.right + 5).toString() + "px";
        this._tip.style.top = (buttonPosition.top - 10).toString() + "px";

        setTimeout(() => {
            this.visible = false;
        }, 4000);
    }

    public setFloor(current: number, max: number) {
        this._label.innerHTML = `Plan ${current} av 14`;
    }
}
