
export class SnapShot {
    public image: HTMLImageElement;

    constructor(private _canvas: HTMLCanvasElement) {
        this.image = this._takeSnapShot();
    }

    private _takeSnapShot() {
        const img = document.createElement("img");
        img.src = this._canvas.toDataURL("image/png");

        return img;
    }

    public pasteSnapShot(pasteDestinationID: string) {
        const infoContainer =
        document.getElementById(pasteDestinationID)!;

        if (!infoContainer) {
            console.error("Couldn't find conatiner");
            return;
        }
        const container = document.createElement("div");
        container.id = 'snapshot-container';
        container.appendChild(this.image);
        infoContainer.prepend(container);
    }
}