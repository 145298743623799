import { BimApi } from "@sweco-ps/embedded";
import Pbf from "pbf";
import { GeoLocation } from "./GeoLocation";
import { VectorTile, VectorTileLayer } from "@mapbox/vector-tile";
import { PolygonMeshBuilder } from "@babylonjs/core/Meshes/polygonMesh";
import {
    Color3,
    Nullable,
    Scene,
    StandardMaterial,
    Vector3
} from "@babylonjs/core";
import { Mesh } from "@babylonjs/core/Meshes/mesh";
import proj4 from "proj4";
import { TransformNode } from "@babylonjs/core/Meshes/transformNode";
import earcut from "earcut";
import { VertexBuffer } from "@babylonjs/core/Meshes/buffer";
import { BaseMap } from "./baseMap";

export class GeoBuildings {
    private _scene: Scene;
    private _tileCache: Map<string, any> = new Map();

    private _baseMap: BaseMap | undefined;

    private _lastLat = 0;
    private _lastLon = 0;

    constructor(private _api: BimApi) {
        this._scene = _api.viewer.scene;
    }

    public visible(show: boolean) {
        const transformNode = this._scene.getTransformNodeByName("buildings");
        if (transformNode) {
            const children = transformNode.getChildMeshes();
            children.forEach((m) => {
                if (m.isEnabled() != show) {
                    m.setEnabled(show);
                }
            });
            this._scene.meshes.forEach((mesh) => {
                if (mesh.name.substring(0, 6) === "merge_") {
                    mesh.setEnabled(show);
                }
            });
            if (this._baseMap) {
                this._baseMap.visible(show);
            }
        }
    }

    public async updateView(coordinate: GeoLocation, reset = false) {
        if (!coordinate.latitude || !coordinate.longitude) {
            return;
        }

        // if lat-lon not change only update the rotation and offset
        const transformNode = this._scene.getTransformNodeByName("buildings");
        if (transformNode && !reset) {
            if (
                this._lastLat == coordinate.latitude &&
                this._lastLon == coordinate.longitude
            ) {
                transformNode.rotation.y = coordinate.rotation; //+ Math.PI / 2
                transformNode.position.y = coordinate.offset;
                return;
            }
        }

        // remove all buildings form scene
        this.cleanGeoBuildingsFromScene();
        const zoom = 16;
        let xTile = Math.floor(
            ((coordinate.longitude + 180) / 360) * (1 << zoom)
        );
        const latitudeInRadians = coordinate.latitude * (Math.PI / 180);
        let yTile = Math.floor(
            ((1 -
                Math.log(
                    Math.tan(latitudeInRadians) +
                        1 / Math.cos(latitudeInRadians)
                ) /
                    Math.PI) /
                2) *
                (1 << zoom)
        );
        // console.log("" + zoom + "/" + xTile + "/" + yTile);

        const size = this._getNumberOfTilesBySunDistance(); // load 6X6 tiles
        const half = Math.floor(size / 2);
        xTile -= half;
        yTile -= half;

        const tilePromise: any[] = [];
        for (let x = 0; x < size; x++) {
            for (let y = 0; y < size; y++) {
                const tileName = zoom + "/" + (xTile + x) + "/" + (yTile + y);
                // check for tile in chache before loading from server
                if (this._tileCache.has(tileName)) {
                    const tile = this._tileCache.get(tileName);
                    console.log(tileName + "_ from cache");

                    this._parseTile(
                        tile,
                        coordinate,
                        xTile + x,
                        yTile + y,
                        zoom
                    );
                    continue;
                }
                // load from server
                const url =
                    "https://api.mapbox.com/v4/mapbox.mapbox-streets-v8/" +
                    // "https://app.sweco.se/osm/data/v3/" +
                    tileName +
                    ".vector.pbf?access_token=pk.eyJ1IjoiYmJyYW5kIiwiYSI6ImNrZjNtcjJybzA0cDcyc25vbGF3Mm51dG4ifQ.GZnZaDr2h53jKk0Aofm-uQ";
                // ".pbf";
                tilePromise.push(
                    this._getVectorTile(
                        tileName,
                        coordinate,
                        xTile,
                        yTile,
                        x,
                        y,
                        zoom,
                        url
                    )
                );

                // const xhr = new XMLHttpRequest();
                // xhr.responseType = "arraybuffer";
                // xhr.onreadystatechange = () => {
                //     if (xhr.readyState == 4 && xhr.status == 200) {
                //         console.log(tileName + " loaded");
                //         this._parseTile(
                //             xhr.response,
                //             coordinate,
                //             xTile + x,
                //             yTile + y,
                //             zoom
                //         );
                //         console.log(tileName + " parsed");

                //         this._tileCache.set(tileName, xhr.response);
                //     }
                // };
                // xhr.open("GET", url, true);
                // xhr.send();
            }
        }
        // set last value to next run
        this._lastLat = coordinate.latitude;
        this._lastLon = coordinate.longitude;
        const tileAll = await Promise.all(tilePromise);
        tileAll.forEach((t: any[]) => {
            this._parseTile(t[0], t[1], t[2], t[3], t[4]);
        });

        this.mergeGeoBuildingsInScene();
        this._baseMap = new BaseMap(coordinate, this._api);
    }

    public swapWms(name: "ortho" | "black") {
        this._baseMap?.swapWms(name);
        const mat = new StandardMaterial("BuildingMaterial");
        if (name == "black") {
            mat.diffuseColor.copyFromFloats(0.3, 0.3, 0.3);
        } else if (name == "ortho") {
            mat.diffuseColor.copyFromFloats(1, 1, 1);
        }
        this._scene.meshes.forEach((mesh) => {
            if (mesh.name.substring(0, 6) === "merge_") {
                console.log(mesh);
                mesh.material = mat;
            }
        });
    }

    private _getNumberOfTilesBySunDistance(): number {
        const distanceToSunCamera = 800;
        if (distanceToSunCamera <= 512) {
            return 4;
        } else if (distanceToSunCamera <= 720) {
            return 6;
        } else if (distanceToSunCamera <= 1024) {
            return 8;
        } else if (distanceToSunCamera <= 1424) {
            return 10;
        } else if (distanceToSunCamera <= 1624) {
            return 12;
        }

        return 6;
    }

    private _getVectorTile(
        tileName: string,
        coordinate: GeoLocation,
        xTile: number,
        yTile: number,
        x: number,
        y: number,
        zoom: number,
        url: string
    ): Promise<any[]> {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        return new Promise(function (resolve, reject) {
            const xhr = new XMLHttpRequest();
            xhr.responseType = "arraybuffer";
            xhr.onreadystatechange = () => {
                if (xhr.readyState == 4 && xhr.status == 200) {
                    // console.log(tileName + " loaded");

                    // console.log(tileName + " parsed");

                    self._tileCache.set(tileName, xhr.response);
                    resolve([
                        xhr.response,
                        coordinate,
                        xTile + x,
                        yTile + y,
                        zoom
                    ]);
                }
            };
            xhr.open("GET", url, true);
            xhr.send();
        });
    }

    public cleanGeoBuildingsFromScene() {
        const transformNode = this._scene.getTransformNodeByName("buildings");
        if (transformNode) {
            // clean up
            const buildings = transformNode.getChildMeshes();
            console.log("Remove try catch here");
            buildings.forEach((mesh) => {
                try {
                    this._api.viewer.selectables.detach(mesh as Mesh);
                    mesh.dispose();
                } catch (e) {
                    console.log(e);
                }
            });
        }
        this._lastLat = 0;
        this._lastLon = 0;
    }

    private async _mergeIntoClusterAndDisposeSeparateBuildings(
        meshToMerge: Mesh[]
    ) {
        // merge into one
        const meshCluster = new Promise<Nullable<Mesh>>((resolve, reject) => {
            resolve(Mesh.MergeMeshes(meshToMerge, false));
        });
        // const meshCluster =  Mesh.MergeMeshes(meshToMerge, false);
        const cluster = (await meshCluster) as Mesh;
        // cluster.renderingGroupId = 1;
        cluster.name = "merge_" + Date.now();
        this._api.viewer.selectables.attach(cluster);

        meshToMerge.forEach((mesh) => {
            // remove from embedded
            try {
                this._api.viewer.selectables.detach(mesh as Mesh);
                mesh.dispose();
            } catch (e) {
                console.log(e);
            }
        });
        console.log("dispose done");
    }

    public mergeGeoBuildingsInScene() {
        const transformNode = this._scene.getTransformNodeByName("buildings");
        if (transformNode) {
            // clean up
            const buildings = transformNode.getChildMeshes() as Mesh[];
            let vertexClusterCount = 0;
            let meshToMerge: Mesh[] = [];
            buildings.forEach((mesh) => {
                // const verteces = mesh.getVerticesData(
                //     VertexBuffer.PositionKind
                // );
                // console.log(verteces);

                const vertexCount = mesh.getTotalVertices();
                // console.log(vertexCount);

                if (vertexCount === 0) {
                    return;
                }
                if (vertexClusterCount + vertexCount < 65536) {
                    vertexClusterCount += vertexCount;
                    meshToMerge.push(mesh);
                } else {
                    this._mergeIntoClusterAndDisposeSeparateBuildings(
                        meshToMerge
                    );
                    // reset cluster
                    vertexClusterCount = vertexCount;
                    meshToMerge = [mesh];
                }
            });
            if (meshToMerge.length > 0) {
                this._mergeIntoClusterAndDisposeSeparateBuildings(meshToMerge);
            }
        }
    }

    private _buildingCount = 0;
    private _idSet = new Set();
    private _parseTile(
        buffer: any,
        coordinate: GeoLocation,
        xTile: number,
        yTile: number,
        zoom: number
    ) {
        const pbf = new Pbf(new Uint8Array(buffer));
        const tile = new VectorTile(pbf);
        // console.log("tile", tile);
        const wgs84 =
            "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees";
        const sweref99TM =
            "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs";

        const origoLonLatSweref99 = proj4(wgs84, sweref99TM, [
            coordinate.longitude,
            coordinate.latitude
        ]);

        const buildings = tile.layers.building;
        if (!buildings) {
            console.log("No buildings for this location");
            return;
        }
        this._generateBuildings(
            coordinate,
            xTile,
            yTile,
            zoom,
            buildings,
            wgs84,
            sweref99TM,
            origoLonLatSweref99
        );

        // console.log(tile.layers);

        // const roads = tile.layers.road;
        // if (!roads) {
        //     console.log("No roads for this location");
        //     return;
        // }
        // this._generateRoads(coordinate, xTile, yTile, zoom, roads, wgs84, sweref99TM, origoLonLatSweref99)
    }

    private _generateRoads(
        coordinate: GeoLocation,
        xTile: number,
        yTile: number,
        zoom: number,
        roads: VectorTileLayer,
        wgs84: string,
        sweref99TM: string,
        origoLonLatSweref99: number[]
    ) {
        let transformNode = this._scene.getTransformNodeByName("roads");
        if (!transformNode) {
            transformNode = new TransformNode("roads", this._scene);
            transformNode.rotation.z = Math.PI;
        }
        transformNode.rotation.y = coordinate.rotation; //+ Math.PI / 2
        transformNode.position.y = coordinate.offset;

        for (let i = 0; i < roads.length; i++) {
            const road = roads.feature(i).toGeoJSON(xTile, yTile, zoom);
            console.log("each road", road);
            if (road.geometry?.type !== "LineString") {
                continue;
            }
            const shape: any = road.geometry.coordinates;

            const vShape: Vector3[] = shape.map(
                (wgs84Coord: [number, number]) => {
                    if (isNaN(wgs84Coord[0]) || isNaN(wgs84Coord[1])) {
                        return new Vector3(0, -100, 0);
                    }

                    const sweref99Coord = proj4(wgs84, sweref99TM, [
                        wgs84Coord[0],
                        wgs84Coord[1]
                    ]);

                    return new Vector3(
                        sweref99Coord[1] - origoLonLatSweref99[1],
                        sweref99Coord[0] - origoLonLatSweref99[0],
                        0
                    );
                }
            );
            const flipShape = vShape.map((v) => {
                return v.clone();
            });
            for (let i = 0; i < flipShape.length - 1; i += 2) {
                const forward = flipShape[i]
                    .subtract(flipShape[i + 1])
                    .normalize();
                const right = forward.cross(Vector3.Forward()).scale(5);
                flipShape[i].addInPlace(right);
                flipShape[i + 1].addInPlace(right);
                vShape[i].subtractInPlace(right);
                vShape[i + 1].subtractInPlace(right);
            }
            flipShape.reverse();
            vShape.push(...flipShape);

            // create a id for this road
            const id =
                "b_" + xTile + "_" + yTile + "_" + zoom + "_" + i + "_road";

            // make sure the id is not already used
            if (this._idSet.has(id)) {
                console.warn("road id already exists " + id);
            } else {
                this._idSet.add(id);
            }

            const polygon_triangulation = new PolygonMeshBuilder(
                id,
                vShape,
                this._scene,
                earcut
            );
            const mesh = polygon_triangulation.build(false);
            mesh.overrideMaterialSideOrientation = 0;

            if (road.id) {
                (mesh as any).roadID = road.id.toString();
            }

            mesh.parent = transformNode;
        }
    }

    private _generateBuildings(
        coordinate: GeoLocation,
        xTile: number,
        yTile: number,
        zoom: number,
        buildings: VectorTileLayer,
        wgs84: string,
        sweref99TM: string,
        origoLonLatSweref99: number[]
    ) {
        // console.log("buildings", buildings);

        // console.log(coordinate);

        // console.log(lonLatSweref99);
        const buildingMinHeight = Vector3.Zero();
        const buildingMaxHeight = Vector3.Zero();

        let transformNode = this._scene.getTransformNodeByName("buildings");
        if (!transformNode) {
            transformNode = new TransformNode("buildings", this._scene);
            transformNode.rotation.z = Math.PI;
        }
        transformNode.rotation.y = coordinate.rotation; //+ Math.PI / 2
        transformNode.position.y = coordinate.offset;

        // const buildingHeightEditor = new BuildingHeightEditor(
        //     this.api,
        //     document.body
        // );

        for (let i = 0; i < buildings.length; i++) {
            const building = buildings.feature(i).toGeoJSON(xTile, yTile, zoom);
            // console.log("each building", building);
            if (
                building.geometry?.type !== "Polygon" &&
                building.geometry?.type !== "MultiPolygon"
            ) {
                continue;
            }
            for (let j = 0; j < building.geometry.coordinates.length; j++) {
                if (building.id) {
                    if (
                        building.id == "247982203" ||
                        building.id == "30850562"
                    ) {
                        console.log("remove buildings by id");
                        continue;
                    }
                }
                let shape: any = building.geometry.coordinates[j];

                if (shape.length === 1 && shape[0].length > 3) {
                    shape = shape[0];
                }

                shape = shape.map((wgs84Coord: [number, number]) => {
                    if (isNaN(wgs84Coord[0]) || isNaN(wgs84Coord[1])) {
                        return new Vector3(0, -100, 0);
                    }

                    const sweref99Coord = proj4(wgs84, sweref99TM, [
                        wgs84Coord[0],
                        wgs84Coord[1]
                    ]);

                    return new Vector3(
                        sweref99Coord[1] - origoLonLatSweref99[1],
                        sweref99Coord[0] - origoLonLatSweref99[0],
                        0
                    );
                });

                buildingMaxHeight.y = 3;
                buildingMinHeight.y = 0;
                if (building.properties?.min_height) {
                    buildingMinHeight.y = building.properties.min_height;
                }
                if (building.properties?.height) {
                    buildingMaxHeight.y = building.properties.height;
                }

                // create a id for this building
                const id =
                    "b_" + xTile + "_" + yTile + "_" + zoom + "_" + i + "_" + j;

                // if building got id, use it instead
                // if (building.id && !this._idSet.has(building.id.toString())) {
                //     id = building.id.toString();
                // }

                // make sure the id is not already used
                if (this._idSet.has(id)) {
                    console.warn("building id already exists " + id);
                } else {
                    this._idSet.add(id);
                }

                // check for stored height data
                const hasCustomHeight = false;

                const polygon_triangulation = new PolygonMeshBuilder(
                    id,
                    shape,
                    this._scene,
                    earcut
                );
                const mesh = polygon_triangulation.build(
                    false,
                    buildingMaxHeight.y
                ) as any;
                // mesh.renderingGroupId = 1;

                if (building.id) {
                    (mesh as any).buildingID = building.id.toString();
                }

                mesh.parent = transformNode;
                const nr = this._api.viewer.selectables.attach(mesh);
                if (nr <= 0) {
                    console.warn(
                        "out of gpu picking space. to many buildings loaded"
                    );
                    mesh.dispose();
                    return;
                }

                // buildingHeightEditor.addSphere(mesh)

                mesh.currentHeight = buildingMaxHeight.y;
                mesh.triangulator = polygon_triangulation;
                mesh.updateHeight = (height: number) => {
                    if (mesh.currentHeight != height) {
                        mesh.currentHeight = height;
                        const newMesh = mesh.triangulator.build(
                            false,
                            height
                        ) as Mesh;
                        const newPositions = newMesh.getVerticesData(
                            VertexBuffer.PositionKind
                        );
                        mesh.setVerticesData(
                            VertexBuffer.PositionKind,
                            newPositions?.slice()
                        );
                        newMesh.dispose();
                    }
                };
                mesh.updateColor = (id: string, color: Color3) => {
                    let material = this._api.viewer.scene.getMaterialByName(
                        id
                    ) as StandardMaterial;
                    if (!material) {
                        material = new StandardMaterial(
                            id,
                            this._api.viewer.scene
                        );
                    }
                    material.diffuseColor.copyFrom(color);
                    mesh.material = material;
                };
                mesh.changed = (): void => {
                    mesh.updateColor(
                        "changed",
                        new Color3(200 / 255, 170 / 255, 120 / 255)
                    );
                };
                if (hasCustomHeight) {
                    mesh.changed();
                }
                this._buildingCount++;
            }
        }
    }
}
